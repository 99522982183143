// src/components/Questionnaire.js
import React, { useState } from 'react';
import questionData from '../questions.json'; // Import the JSON data
import '../styles/questionnaire.css';


const CircleRating = ({ id, onChange, value }) => {
  const maxRating = 5;
  const ratings = Array.from({ length: maxRating + 1 }, (_, index) => index);

  return (
    <div className="circle-rating-container">
      {ratings.map((rating) => (
        <span
          key={rating}
          className={`circle${value === rating ? ' selected' : ''}`}
          onClick={() => onChange(id, rating)}
        >
          {rating}
        </span>
      ))}
    </div>
  );
};

const BinaryChoice = ({ id, options, onChange, value }) => {
    return (
      <div className="binary-choice-container">
        {options.map((option, index) => (
          <button
            key={index}
            className={`binary-option${value === option ? ' selected' : ''}`}
            onClick={() => onChange(id, option)}
          >
            {option}
          </button>
        ))}
      </div>
    );
  };
const initialQuestions = questionData.map((q) => ({ ...q, value: q.type === 'range' ? 0 : null }));


function Questionnaire() {
  
  const [answers, setAnswers] = useState(initialQuestions);
  const [result, setResult] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const allQuestionsAnswered = answers.every(question => {
    return question.type === 'range' ? question.value > 0 : question.value !== null;
  });

  const handleRatingChange = (id, value) => {
    setAnswers(
      answers.map((question) =>
        question.id === id ? { ...question, value: value } : question
      )
    );
  };

  const handleBinaryChange = (id, value) => {
    setAnswers(
      answers.map((question) =>
        question.id === id ? { ...question, value: value } : question
      )
    );
  };

  const calculateResult = () => {
    let totalScore = 0;
    let totalPossibleScore = 0;
  
    answers.forEach(question => {
      if (question.type === 'range') {
        totalScore += question.value;
        totalPossibleScore += 5; // Max score for range questions
      } else if (question.type === 'binary') {
        // Here you need to decide how to score the binary questions
        // This is just an example where one option is worth 5 and the other 0
        totalScore += question.value === question.options[0] ? 0 : 5;
        totalPossibleScore += 5; // Max score for binary questions
      }
    });
  
    const score = (totalScore / totalPossibleScore) * 100;
    setResult(Math.round(score)); // Round the score to an integer
    setShowModal(true); // Show the modal with the result
  };
   

  const closeModal = () => {
    setShowModal(false); // Hide the modal
  };

  return (
    <div className="questionnaire-container">
      <div className="question-description">
      Ready to test your Martin mojo? Give your best answers and let’s find out if you’re a flirtatious fit for Martin. Score away and let the sparks fly!      </div>
      {answers.map((question) => (
        <div key={question.id} className="question">
          <label htmlFor={question.id} className="label">{question.label}</label>
          {question.type === 'range' ? (
            <CircleRating
              id={question.id}
              value={question.value}
              onChange={handleRatingChange}
            />
          ) : (
            <BinaryChoice
              id={question.id}
              options={question.options}
              value={question.value}
              onChange={handleBinaryChange}
            />
          )}
        </div>
      ))}
      {!allQuestionsAnswered && (
        <div className="submit-instruction">
          Please answer all questions before submitting.
        </div>
      )}
      <button onClick={calculateResult} disabled={!allQuestionsAnswered}>Submit</button>
      {showModal && <ResultModal score={result} onClose={closeModal} />}
    </div>
  );  
    
}

const ResultModal = ({ score, onClose }) => {
    return (
      <div className="modal-overlay">
        <div className="modal">
          <h2>Your Compatibility Score</h2>
          <div className="score-result">{score}</div>
          <p>You scored {score} out of 100</p>
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    );
  };

export default Questionnaire;
