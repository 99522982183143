// src/App.js
import React from 'react';
import './styles/questionnaire.css';
import Questionnaire from './components/Questionnaire';

function App() {
  return (
    <div className="App">
      <Questionnaire />
    </div>
  );
}

export default App;
